.page_3 {

    .content {
        h3 {
            font-weight: 400;
            line-height: 34px;
            font-size: 21px;
        }    

        p {
            font-size: 15px;
        }
    }
    

    #v2-voucher-axel {
        padding: 50px 30px;
        max-width: 600px;
        margin: 0 auto;
    }
}