// spot


// ********************
// anchor helper

#spots {
	position: relative;
	top: -50px;
}

// ***************

.spots-sec {
	// background-color: #f0f0f0;
	background-color: #d0e0e9;
}

// ********************

.spot {
	position: relative;
}

.spot-icon {
	position: absolute;
	z-index: -1;
	color: $gray-300; // #f0f0f0;
}

.spots {
	margin-top: 0;
	margin-bottom: 0;

	div.row {
		padding-top: 0;
		padding-bottom: 0;
	}
	
	.row > div {
		padding-top: 15px;
	}

	.container {
		// overflow: visible;
	}

	.spot-content {
		position: relative;
		z-index: 11;
	}

	.spot-icon {
		// position: absolute;
		z-index: 10;
	}
	.spot-icon i,
	.spot-icon .glyphicons {
		font-size: 110px;
	}

	// ---
	.spot-log-in      { top: 8px; right: 20px; }
	.spot-edit        { top: -5px; right: -5px; }
	// .spot-user        { top: 15px; right: 5px; }
	.spot-user        { top: 20px; right: 10px; }
	.spot-map-marker  { top: 20px; right: 0px; }
	.spot-gaia        { top: 15px; right: 5px; }
	.spot-chat        { top: 15px; right: 5px; }
	.spot-envelope    { top: 20px; right: 10px; }
	.spot-play        { top: 15px; right: 10px; }
	.spot-compass     { top: 20px; right: 5px; }
	.spot-xing        { top: 20px; right: 20px; }
	.spot-linkedin    { top: 20px; right: 20px; }
	.spot-share       { top: 10px; right: 10px; }
	.spot-tellafriend { top: 10px; right: 10px; }
	.spot-facebook    { top: -10px; right: 20px;
		i { font-size: 80px; }
	}
	.spot-heart        { top: 20px; right: 10px; }
	.spot-shopping-cart{ top: 0px; right: 10px; }
	.spot-more-items   { top: 15px; right: 0px; }
	.spot-group        { top: 20px; right: 30px; }
	.spot-parents      { top: 20px; right: 20px; }
	.spot-shield       { top: 20px; right: 5px; }
}

@include media-breakpoint-up (sm) {
	.spots {
		// margin-top: 0;
		margin-bottom: 30px;
		overflow: visible;
	}
}
