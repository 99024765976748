
.btn-app {
	background-color: $brand-app;
	border-color: $brand-app;
	color: #fff;
}

.btn-app:hover, .btn-app:focus, .btn-app:active, .btn-app.active, .open>.dropdown-toggle.btn-app {
	color: #fff;
	background-color: lighten($brand-app, 5%);
    border-color: $brand-app; /*set the color you want here*/
}

.btn-app:not(:disabled):not(.disabled).active, 
.btn-app:not(:disabled):not(.disabled):active, 
.show>.btn-app.dropdown-toggle{
	color: #fff;
	background-color: lighten($brand-app, 5%);
	border-color: lighten($brand-app, 5%);
}

.btn-custom {
	color: #333;
    background-color: #fff;
    border-color: #ccc;
}