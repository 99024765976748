.bob-frame.contains-v1-voucher-dak,
.bob-frame.contains-v2-voucher-axel,
.bob-frame.contains-z1-zen-bgm,
.bob-frame.contains-s1-success-frame,
.bob-frame.contains-f1-fallback-form {

    .bob-window {
        margin: 0 auto;

        .bob-header {
            img {
                margin: 25px 0 0 0;
            }
        }
    }

    .content {

        h3 {
            font-family: $font-family-base;
            font-weight: 400;
            line-height: 34px;
            font-size: 21px
        }

        p {
            font-size: 15px;
        }
    }

    .kvnr-info {
        font-size: 16px;
        margin-top: 5px;
        opacity: .6
    }
}

@include media-breakpoint-up(md) {

    .bob-frame.contains-v1-voucher-dak,
    .bob-frame.contains-v2-voucher-axel,
    .bob-frame.contains-z1-zen-bgm,
    .bob-frame.contains-s1-success-frame,
    .bob-frame.contains-f1-fallback-form {

        .bob-window {
            width: 600px;
        }
    }   
} 


