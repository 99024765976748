//@media (max-width: @screen-xs-max) {  bootstrap 3
@include media-breakpoint-up(sm) {
  .container {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@include media-breakpoint-up(md) {
  .container, .navbar-expand>.container {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

// @media (max-width: @screen-phone) {  bootstrap 3 480px
// .container {
//   width: 100%;
//   padding-left: 10px; // 30px;
//   padding-right: 10px; // 30px;
// }

.bob-frame {
  .bob-header {
    img {
        height: 44px;
        width: 150px;
    }
  }
}