// passage

// anchor helper
.passage-anchor {
	position: relative;
	top: -100px;
}
// .passage-anchor#health-providers-2,
// .passage-anchor#payers-2,
// .passage-anchor#employers-2,
// .passage-anchor#online-treatment {
//   top: -50px;
// }

// ********************

.passage {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 0;

  img {
    display: block;
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 0 auto;
  }
}
@include media-breakpoint-up (sm) {
  .passage {
    padding-bottom: 30px;
  }
}
// ***************

.passage-last {
  margin-bottom: 50px;
}

// ********************

// .passage-eve,
// .passage-ppe,
// .passage-ppe2 {
//   h3 {
//     color: #EB7A7A;
//   }
// }

// .passage {
//   a {
//     color: #333;
//     text-decoration: none;
//     border-bottom: 1px solid #333;
//   }
// }

// .passage-inverse {
//   a {
//     color: #fff;
//     text-decoration: none;
//     border-bottom: 1px solid #fff;
//   }
// }
