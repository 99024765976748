// jumbotron
// ---------

.jumbotron {
	// height: 300px;
  background-color: $brand-hero;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @include media-breakpoint-up(sm) {

    h1 {
      font-size: ($font-size-base * 3.5);
    }
	p {
		font-size: $font-size-base;
	}
  }
}

// ***************
.page_1 {

  .jumbotron {
    margin-top: 0; // 5px;
    margin-bottom: 0;
    padding: 0;
  
    // min-height: 300px;
    background-image: url("/images/rueckenfit_hero_mob.jpg");
    background-size: cover;
    background-position: center center;
    // background-position: 30% center;
  
    height: -moz-calc(100% - 50px);
    height: -webkit-calc(100% - 50px);
    height: calc(100% - 50px);
  }
}


.jumbotron .container {
  position: relative;
  height: 100%;
  // height: 300px;
}

.jumbotron-content {
  padding-top: 20px;
  z-index: 11;

  h1 {
    font-size: ($font-size-base * 1.5);
    margin-bottom: 4px;
  }

  h3 {
    display: block;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: ($font-size-base * 0.8);
  }

  h4 {
    display: none;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    font-size: ($font-size-base * 0.8);
    // text-shadow: 1px 1px 6px rgba(0,0,0,0.2);
    margin-bottom: 0;
  }

  p {
    // text-shadow: 1px 1px 6px rgba(0,0,0,0.1);
    font-size: ($font-size-base * 0.8);
  }

  .jumbotron-content-exclusive {
    
    img {
      width: auto;
      height: 30px;
      vertical-align: middle;
      margin:0 10px 10px 0;
    }
  }
}

.jumbotron-link {
  margin: 6px 0 12px;

  .glyphicons {
    top: 0px;
  }

  .chevron-right {
    height: 24px;
    width: 24px;
    fill: #fff;
   }
   

}

// @include media-breakpoint-down(sm) {
//   .jumbotron-link {
//     a {
//       .btn-sm();
//     }
//   }
// }

.jumbotron-logo {
  position: absolute;
  bottom: 15px;
  right: 15px;

  img {
    width: 180px;
    height: auto;
  }
}

@include media-breakpoint-up(md) {


  .page_1 {
    .jumbotron {
      // min-height: 400px;
      height: 400px;
      background-image: url("/images/rueckenfit_hero_desk.jpg");
    }
  }

  .jumbotron-content {
    padding-top: 25px;
    width: 75%;

    h1 {
      font-size: ($font-size-base * 2);
    }
    h3 {
      display: none;
    }
    h4 {
      display: block;
    }
    p {
      font-size: ($font-size-base * 1);
    }
  }
  .jumbotron-logo {
    display: none;
  }
}


///////////page_2

.page_2 {
  .jumbotron {
    background-image: url("/images/hero_bgm.jpg");
    margin-top: 0; // 5px;
    margin-bottom: 0;
    padding: 0;
    
    // min-height: 300px;
    background-size: cover;
    background-position: 44% 29%;
    // background-position: 30% center;
    
    height: -moz-calc(100% - 50px);
    height: -webkit-calc(100% - 50px);
    height: calc(100% - 50px);
  }
}

@include media-breakpoint-up(md) {
  .page_2 {
    .jumbotron {
      height: 400px;
    }
  }
 }

 @include media-breakpoint-up(lg) {
  .page_2 {
    .jumbotron {
      background-position: 10% 30%;
    }
  }
 }

 @include media-breakpoint-up(xl) {
  .page_2 {
    .jumbotron {
      background-image: url("/images/hero_bgm_lg.jpg");
      background-position: 10% 40%;
    }
  }
 }