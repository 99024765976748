// cta


// ********************
// anchor helper

#cta-form {
	position: relative;
	top: -50px;
}

// ********************

.cta-item {
	position: relative;
}

.cta {
	margin-top: 0;
	margin-bottom: 20px;

	div.row {
		padding-top: 0;
		padding-bottom: 0;
	}

	.row>div {
		padding-top: 30px;
		// padding-bottom: 30px;
	}

}

@include media-breakpoint-up (sm) {

	.cta {
		margin-top: 0;
		margin-bottom: 20px;
		overflow: visible;

		div.row {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
}


// ********************

.cta-form .cta-item {

	font-weight: 300;

	label {
		font-weight: 300;
	}

	a {
		color: #333;
		text-decoration: none;
		// border-bottom: 1px solid #333;
	}

}

.page_2 {
	.cta-form .cta-item {
		h2 {
			font-size: 25px;
		}
	}
}

.cta-form .cta-image {
	display: none;
}

@include media-breakpoint-up (sm) {

	.cta-form .cta-image {
		display: block;
	}
}

.cta-image img {
	width: 200px;
	height: auto;
	margin-top: 0;
}

@include media-breakpoint-up (sm) {
	.cta-image img {
		width: 100%;
		// max-width: 232.5px;
		max-width: 132.5px;
		height: auto;
		margin-top: 5px;
	}
}

// form

.container-form,
.container-form-confirm {
	position: relative;
	padding: 30px;
	min-width: 300px;

}

.form-group.is-invalid {

	.control-label,
	.form-control-description {
		color: var(--danger);
	}

	.form-control {
		border: 1px solid #ff0000;
	}
}

#frame-cancle-btn {
	&:hover {
		cursor: pointer;
	}
}

.form-control-description {
	margin-top: 5px;
	opacity: 0.6;
	font-size: 16px;
}

@include media-breakpoint-up(md) {

	.container-form-confirm {
		margin-top: 100px;
	}

}