// ***************
// spots-usps

#usps {
	// position: relative;
	// top: -50px;
}

// ***************
.page_1 {
	.usps {
		background-color: #8da2ae; // #153047; // #666666;
		color: #fff;
	}
	
	.usp-content {
		margin-left: 20px;
		position: relative;
		// z-index: 11;
	}
	.usp-icon {
	
		svg {
			font-size: 110px;
			width: 120px;
			height: 120px;
			fill: #fff;
			opacity: 0.8;
		}
	}
}



.usps {
	display: none;
	margin-top: 5px;
	margin-bottom: 0;
	padding-top: 10px;

	.container {
		overflow: visible;
	}

	.row {
		padding-top: 0px;
		padding-bottom: 15px;

		.usp {
			padding-top: 30px;
		}
	}

	.usp-icon {
		position: absolute;
		// z-index: -1;
		z-index: 10;
		// color: @gray-lighter; // #f0f0f0;

		i {
			font-size: 50px;
			color: #fff;
			opacity: 0.2;
		}
	}


	h2 {
		font-size: 20.3px;
		font-weight: 200;
		line-height: 24px;
	}

	// p {
	// 	font-size: ($font-size-base * 0.7);
	// }
	a {
		color: #fff;
		opacity: 0.8;
	}

	a:hover,
	a:active,
	a:focus,
	a:link,
	a:visited {
		color: #fff;
		text-decoration: none;
		border-bottom: 1px solid #fff;
	}

	a:hover {
		opacity: 1;
	}
}

@include media-breakpoint-up (sm) {

	.usps {
		display: block;
		// margin-top: 5px;
		// margin-bottom: 0px;

		.usp-icon i,
		.usp-icon .glyphicons {
			font-size: 60px;
		}

		.usp-content {
			margin-left: 40px;
		}
	}
}

@include media-breakpoint-up (lg) {

	.usps {

		h2 {
			line-height: 28px;
			font-size: 23px;
		}

		// p {
		// 	font-size: ($font-size-base * 0.8);
		// }
	}
}

.usps {
	.usp-tick {
		top: -30px;
		left: -23px;
	}
}

// @include media-breakpoint-up (sm) { 

// 	.usps {
// 		.usp-tick { top: 0px; left: 10px; }
// 	}
// }

///////////////page_2

.page_2 {
	.usps {
		background-color: #fff;
		color: #333;

		h2 {
			font-weight: 400;
		}
	}

	.usp-content {
		margin-left: 0;
		margin-top: 45px;
		// position: relative;
		// z-index: 11;
	}
	.usp-icon {
		display: none;
	}
}